import React from 'react';
import { Select, TreeSelect } from 'antd';
import { Controller } from 'react-hook-form';
const { SHOW_PARENT } = TreeSelect;

export const SelectDropdown = React.memo(({ item, onSelect, control, setValue }) => {
	return (
		<Fragment>
			<Controller
				name={item.name}
				control={control}
				rules={item.rules}
				// value={item.value}
				defaultValue={item.defaultValue}
				render={({ onChange, onFocus, onBlur, onSearch, value }) => (
					<Select
						showSearch
						showArrow
						placeholder={item.placeholder}
						optionFilterProp="children"
						onSearch={onSearch}
						onFocus={onFocus}
						defaultValue={item.defaultValue}
						value={value || item.defaultValue}
						disabled={item.disabled}
						onBlur={onBlur}
						style={{ width: '100%', margin: '5px 0px', borderRadius: '10px' }}
						onChange={item.onSelect ? (e) => item.onSelect(e, item.name, setValue) : onChange}
						onSelect={(val, option) => onSelect && onSelect({ name: item.name, option })}
						// filterOption={(input, option) =>
						// 	option.children.indexOf(input.toLowerCase()) >= 0
						// }
						notFoundContent={null}
					>
						{!!item.options && item.options.map((_option, _idxO) => {
							// {[{label: '1', value: '1'}].map((_option, _idxO) => {
							return (
								<Select.Option key={Number(_idxO)}
									others={_option.others || null}
									value={_option.value}>
									{_option.label}
								</Select.Option>
							)
						})}
					</Select>
				)}
			/>
		</Fragment>
	);

}, (prevProps, nextProps) => {
	return false
}
	//  [prevProps.value[prevProps.item.options] !== nextProps.value[nextProps.item.options]]
)

export const TreeSelectDropdown = React.memo(({ item, onSelect, control, setValue, options, onChangeCustom }) => {
	return (
		<Fragment>
			<Controller
				name={item.name}
				control={control}
				rules={item.rules}
				// value={item.value}
				defaultValue={item.defaultValue}
				render={({ onChange, onFocus, onBlur, onSearch, value }) => (
					<TreeSelect
						treeData={item.options}
						value={item.value}
						onChange={item.onChangeCustom}
						treeCheckable={true}
						showCheckedStrategy={SHOW_PARENT}
						placeholder={'Please select'}
						style={{
							width: '100%',
							margin: '5px 0px',
						}}
					/>
				)}
			/>
		</Fragment>
	);

}, (prevProps, nextProps) => {
	return false
}
	//  [prevProps.value[prevProps.item.options] !== nextProps.value[nextProps.item.options]]
)