export const color = {
	theme: '#1890ff',

	edit: '#ff7b54',
	submit: '#16c79a',
	clear: '#707070',
	remove: '#d35d6e',
	search: '#1890ff',


}