import { computed, observable, action } from 'mobx'
export default class ManageUserCustomerMobx {
    @observable userIdAction = ''
	
	@action
    setUserIdAction(data) {
        this.userIdAction = data
    }

	@computed
    get getUserIdAction() {
        return this.userIdAction
    }

}

// export default new UserMobx();