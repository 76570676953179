import React from 'react'
import { DatePicker } from 'antd'
import { Controller } from "react-hook-form";

export const DatePickerForm = React.memo(({ item, errors, clearErrors, control }) => {
	return (
		<Controller
			name={item.name}
			control={control}
			rules={item.rules}
			value={item.value}
			defaultValue={item.defaultValue}
			render={({ onChange, value }) => (
				<DatePicker
					disabledDate={item.disabledDate}
					disabled={item.disabled}
					value={value}
					style={{ width: '100%', marginTop: 5 }}
					onChange={onChange}
				// onChange={(errors[item.name]) ? clearErrors(item.name) && onChange : onChange}
				/>
			)}
		/>
	)
}, false)