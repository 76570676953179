import './style'
import App from './App';
import { StoreProvider } from './helpers/store-provider';
import UserMobx from './mobx/UserMobx';
import MenuMobx from './mobx/MenuMobx';
import ManageUserMobx from './mobx/ManageUserMobx';
import ManageUserCustomerMobx from './mobx/ManageUserCustomerMobx';

const Wrap = () => (
	<StoreProvider value={{
		user: new UserMobx(),
		menu: new MenuMobx(),
		manageUser: new ManageUserMobx(),
		manageUserCustomer: new ManageUserCustomerMobx(),
	}}>
		<App />
	</StoreProvider>
);

export default Wrap
