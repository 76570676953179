import { create } from 'apisauce';
import { getCookies } from '../store/useCookies';
import UserMobx from '../mobx/UserMobx';
import { TOKEN_WEB_NAME } from '../config/config';
//export const ip = 'http://localhost:2036/api/v1/etax'; //temp ip
export const ip = 'https://demo.zetaxsoft.com:3036/api/v1/etax'; //temp ip

export const MODE_PORT = {
  LOCALHOST: 2036,
  DEVELOP: 2036,
  PRODUCTION: 3036
}

export const CONNECT_PORT = MODE_PORT.PRODUCTION;  //! Don't Forgot Change Mode before Build

export const MODE_API = {
  LOCALHOST: `http://localhost:${CONNECT_PORT}/api/v1/etax`,
  DEVELOP: `http://localhost:${CONNECT_PORT}/api/v1/etax`,
  PRODUCTION: `https://demo.zetaxsoft.com:${CONNECT_PORT}/api/v1/etax`
}

export const CONNECT_API = MODE_API.PRODUCTION;  //! Don't Forgot Change Mode before Build

export const MODE_API_EXCEL = {
  LOCALHOST: `http://localhost:${CONNECT_PORT}/api/v1/`,
  DEVELOP: `http://localhost:${CONNECT_PORT}/api/v1/`,
  PRODUCTION: `https://demo.zetaxsoft.com:${CONNECT_PORT}/api/v1/`
}

export const CONNECT_API_EXCEL = MODE_API_EXCEL.PRODUCTION;

const api = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 300000 // 10 seconds
})

const apiupload = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    'Accept': 'multipart/form-data'
  }
})

const apifin = create({
  baseURL: 'https://www.fininsurance.co.th',
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data",
    'Accept': 'multipart/form-data'
  }
})

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path 
 * @param {{}} obj 
 * @return {{}}
 */
export const POST = (path, obj, token = getCookies(TOKEN_WEB_NAME)) =>
  new Promise((resolve, reject) => {
    api.post(path, obj, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response.data)
        } else {
          response.data ? reject(response.data) : reject({ success: false, message: response.problem })
        }
      }).catch(err => reject(err))
  })


/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path 
 * 
 */
export const GET = (path, token = getCookies(TOKEN_WEB_NAME)) =>
  new Promise((resolve, reject) => {
    api.get(path, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8'
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response.data)
        } else {
          response.data ? reject(response.data) : reject({ success: false, message: response.problem })
        }
      }).catch(err => reject(err))
  })


export const UPLOAD = (path, formdata, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    apiupload.post(path, formdata, {
      headers: {
        'Authorization': token,
      }
    })
      .then(response => {
        if (response.ok) {
          resolve(response.data)
        } else {
          response.data ? reject(response.data) : reject({ success: false, message: response.problem })
        }
      }).catch(err => reject(err))
  })

export const UPLOADOtherAPI = (path, formdata) =>
  new Promise((resolve, reject) => {
    apifin.post(path, formdata, {})
      .then(response => {
        if (response.ok) {
          resolve(response.data)
        } else {
          response.data ? reject(response.data) : reject({ success: false, message: response.problem })
        }
      }).catch(err => reject(err))
  })

/* ################################################## URL ################################################## */
export const LOGIN = `/authen/login`;
export const GET_COMPANY_LOGIN = `/authen/getCompany`;
export const LOGOUT = `/authen/logout`;
export const REGISTER = `/authen/register`;
export const GET_BUYER_BY_ID_CARD = `/authen/getBuyerByIdCard`;
export const FORGET_PASSWORD = `/user/forgetPassword`;
export const CHANGE_PASSWORD = `/user/changePassword`;

export const GET_PROFILE = `/user/getProfile`

/** manageUser **/
export const GET_ALL_USER = `/user/getAllUser`;
export const REMOVE_USER = `/user/removeUser`;
export const GET_MENUS_BY_ROLE = `/master/getMenusByRole`;
export const GET_MENUS_BY_USER = `/user/getMenusByUser`;
export const INSERT_USER = `/user/insertUser`;
export const UPDATE_USER = `/user/updateUser`;

export const INSERT_USER_CUSTOMER = `/user/insertUserCustomer`;
export const UPDATE_USER_CUSTOMER = `/user/updateUserCustomer`;

/** configMail **/
export const GET_CONFIG_MAIL = `/user/getConfigMail`;
export const INSERT_CONFIG_MAIL = `/user/insertConfigMail`;

/** document **/
export const GET_ALL_DOCUMENT = `/document/getAllDocument`;
export const UPDATE_EMAIL = `/document/updateEmail`;
export const RESEND_MAIL_PDF = `/document/resendMailPDF`;
export const GET_DOCUMENT_ZIP = `/document/getDocumentPdfXmlZip`;
export const UPDATE_REMARK_DOCUMENT = `/document/updateRemarkDocument`;

/** receipt document */
export const GET_ALL_RECEIPT_DOCUMENT = `/document/getAllReceiptDocument`;
export const UPDATE_EMAIL_RECEIPT = `/document/updateEmailReceipt`;
export const GET_DOCUMENT_RECEIPT_ZIP = `/document/getReceiptDocumentPdfZip`;

/** zip XML **/
export const GET_DOCUMENT_SUMMARY = `/document/getDocumentSummary`;
export const DOWNLOAD_ZIP_XML = `/document/downloadZipXml`;


/** Dashboard **/
export const GET_DASHBOARD = `/document/getDashboard`;

/** Dashboard : customer **/
export const GET_DASHBOARD_CUSTOMER = `/document/getDashboardCustomer`;

/** Signed History **/
export const GET_SIGNED_HISTORY = `/product/getSignedHistory`;

/** Missing Doc **/
export const GET_MISSING_DOC = `/document/getMissingDocument`;

/** purchaseHistory : customer **/
export const GET_PURCHASE_HISTORY = `/document/getPurchaseHistoryByUser`;
export const GET_DOCUMENT_PDF_ZIP = `/document/getDocumentPdfZip`;
export const GET_DOCUMENT_PDF_VIEW = `/document/getDocumentPdfView`;

/** receiptHistory : customer **/
export const GET_RECEIPT_HISTORY = `/document/getReceiptHistoryByUser`;


/** manageUser : customer **/
export const GET_ALL_USER_CUSTOMER = `/user/getAllUserCustomer`;
export const GET_BRANCH_STORE_BY_USER = `/user/getBranchStoreByUser`;
export const REMOVE_USER_CUSTOMER = `/user/removeUserCustomer`;

/* option master */
export const GET_OPTION_ROLE = `/master/getUtilityOptions/ROLE`;
export const GET_OPTION_ROLE_CUSTOMER = `/master/getUtilityOptions/ROLE_CUSTOMER`;
export const GET_OPTION_ACTIVE_STATUS = `/master/getUtilityOptions/ACTIVE_STATUS`;
export const GET_OPTION_ACTION_PAGE = `/master/getUtilityOptions/ACTION_PAGE`;
export const GET_OPTION_DOC_TYPE_TREE = `/master/getUtilityOptions/DOC_TYPE_TREE`;
export const GET_OPTION_DOC_TYPE = `/master/getUtilityOptions/DOC_TYPE`;
export const GET_OPTION_DOC_TYPE_COMPANY = `/master/getDocTypeCompany`;
export const GET_OPTION_MONTH = `/master/getUtilityOptions/MONTH`;
export const GET_OPTION_YEAR = `/master/getUtilityOptions/YEAR`;
export const GET_FILE_TYPE = `/master/getUtilityOptions/FILE_TYPE`;
export const GET_STORE_BRANCH_BY_BUYER = `/master/getStoreBranchByBuyerNumber`;

/* export excel */
export const GET_EXCEL_SIGNED_HISTORY = `/excel/getExcelSignHistory`;
export const GET_EXCEL_MISSING_DOCUMENT = `/excel/getExcelMissingDocument`;
export const GET_EXCEL_ALL_DOCUMENT = `/excel/getExcelAllDocument`;
export const GET_EXCEL_ALL_DOCUMENT_RECEIPT = `/excel/getExcelAllReceiptDocument`;


