import { computed, observable, action } from 'mobx'
import { TOKEN_WEB_NAME } from '../config/config';
import { getCookies } from '../store/useCookies';
export default class UserMobx {
    @observable isLogin = true
    @observable name = '';
    @observable companyLogin = [];
    @observable count = 0;
    @observable access_token = '';
    @observable role = 'ADMIN';
    @observable menus = ['1']
    @observable user = {}

    constructor() {
        this.onInitial();
    }

    @action
    onInitial() {
        try {
			getCookies(TOKEN_WEB_NAME) ? this.setCheckLogin(true) : this.setCheckLogin(false)
        } catch (error) {
			
        }
    }
	
	@action
    setCheckLogin(data) {
        this.isLogin = data
	}
	
	@action
    setUser(data) {
        this.user = data
    }

	@action
    setCompanyLogin(data) {
        this.companyLogin = data
    }

    @computed
    get getCompanyLogin() {
		return this.companyLogin
	}


    @computed
    get onCheckLogin() {
        return getCookies(TOKEN_WEB_NAME) ? true : false
	}

	@computed
    get getUser() {
        return this.user
    }

}

// export default new UserMobx();