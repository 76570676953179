import { Route, route, Router } from 'preact-router'
import RootRoute from './routes/Root'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import NotfoundScene from './screens/NotfoundScene'
import AccountRoute from './routes/AccountRoute'
import { useStore } from './helpers/use-store'
import './style/index.scss'
import ForgetPasswordScene from './screens/admin/ForgetPasswordScene'
import ReactGA from "react-ga";


const App = () => {
	function handleRouter(e) {
		const { user } = useStore();
		const allowed = user.onCheckLogin

		ReactGA.initialize('UA-194725587-1');
		ReactGA.pageview(window.location.pathname + window.location.search);

		if (!allowed) {
			if (e.url.indexOf('admin') >= 0) {
				return route(e.url)
			}
			if ((e.url) && (e.url).includes('admin')) {
				return route('/admin/login')
			}
			if (e.url.indexOf('account') >= 0) {
				return route(e.url)
			}
			return route('/account/login')
		}
		if (e.url.indexOf('/account/') >= 0) return route('/')
		// if (e.url.indexOf('/admin') == 0) return route('/admin/dashboard')
	}

	return (
		<div>
			<Router onChange={e => handleRouter(e)}>
				<AccountRoute path='/account/:rest*' />
				<ForgetPasswordScene path='/admin/forgetPassword' />
				<AuthenticatedRoute
					path='/:rest*'
					login='/'
					route={RootRoute}
				/>
				<Route path='*' default component={NotfoundScene} />
			</Router>
		</div>

	)
}

export default App
