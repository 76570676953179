import { computed, observable } from 'mobx'
export default class MenuMobx {

	@observable menus = []

	@computed
    get getMenus() {
        return this.menus
	}
	
}

// export default new UserMobx();