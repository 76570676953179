import { Input } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import { compareRender } from '../../functions/fn';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
const { TextArea } = Input;

export const InputLabel = React.memo(({ item, onChange, control }) => {
	return (
		<Controller
			control={control}
			name={item.name}
			rules={item.rules}
			// value={item.value}
			defaultValue={item.defaultValue}
			render={({ onChange, value }) => (
				<Input
					name={item.name}
					prefix={item.prefix}
					value={value || item.defaultValue}
					disabled={item.disabled}
					placeholder={item.placeholder}
					onChange={item.onChangeCustom ? e => item.onChangeCustom(e.target.name, e.target.value):  e => onChange(e.target.value)}
					style={{ margin: '5px 0px', borderRadius: '10px' }}
				/>
			)}
		/>
	)

}, compareRender)

export const InputPassword = React.memo(({ item, onChange, control }) => {
	return (
		<Controller
			control={control}
			name={item.name}
			rules={item.rules}
			value={item.value}
			defaultValue={item.defaultValue}
			render={({ onChange, value }) => (
				<Input.Password
					iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
					name={item.name}
					prefix={item.prefix}
					value={value}
					disabled={item.disabled}
					placeholder={item.placeholder}
					onChange={e => onChange(e.target.value)}
					style={{ margin: '5px 0px', borderRadius: '10px' }}
				/>
			)}
		/>
	)

}, compareRender)


export const InputAreaLabel = React.memo(({ item, onChange, control }) => {
	return (
		<Controller
			control={control}
			name={item.name}
			rules={item.rules}
			// value={item.value}
			defaultValue={item.defaultValue}
			render={({ onChange, value }) => (
				<TextArea
					name={item.name}
					prefix={item.prefix}
					value={value || item.defaultValue}
					disabled={item.disabled}
					placeholder={item.placeholder}
					rows={6}
					showCount 
					maxLength={item.maxLength || 500}
					onChange={item.onChangeCustom ? e => item.onChangeCustom(e.target.name, e.target.value):  e => onChange(e.target.value)}
					style={{ margin: '5px 0px', borderRadius: '10px' }}
				/>
			)}
		/>
	)

}, compareRender)